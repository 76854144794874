import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import './Upload.css';

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [transcriptionReady, setTranscriptionReady] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [vectorstoreResult, setVectorstoreResult] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    setFileUploaded(true);
  }, []);

  const transcribe = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', selectedFile);

    axios.post('https://minuteinminutes-ed5a7d6df2cd.herokuapp.com/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      console.log(response.data);
      setTranscription(response.data.transcription);
      setVectorstoreResult(response.data.vectorstore_result);
      setTranscriptionReady(true);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="app">
      <h1 className="title">Meeting Minute Generator</h1>
      <div className="upload-area">
        <label className="upload-label">Upload Audio File</label>
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
        {fileUploaded && <button className="transcribe-button" onClick={transcribe}>Transcribe</button>}
      </div>
      <div className="status-messages">
        {/* Display status messages here */}
      </div>
      {transcriptionReady && (
        <>
          <button className="download-button">Download File</button>
          <div className="text-area">
            <h2>Transcription:</h2>
            <p>{transcription}</p>
            <h2>Vectorstore Result:</h2>
            <p>{vectorstoreResult}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Upload;
